import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyACThsUSq8-5_V0tJ55w7ZSHPXXV_2IKqU",
  authDomain: "estudio-lmg.firebaseapp.com",
  databaseURL: "https://estudio-lmg-default-rtdb.firebaseio.com",
  projectId: "estudio-lmg",
  storageBucket: "estudio-lmg.appspot.com",
  messagingSenderId: "964344906124",
  appId: "1:964344906124:web:17419a0e9bd245c73856a9",
  measurementId: "G-XBLZF3GM7C"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getDatabase(app);
export default app;