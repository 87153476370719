import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { auth } from "../base";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    function onRegister() {
      signInWithEmailAndPassword(auth, email, password).catch((error) =>
        console.log(error)
      );
      navigate("/");
    }
    onRegister();
  };

  return (
    <div className="mainContainer login">
      <div className="container">
        <div className="title">
          <h2>Iniciar Sesión</h2>
        </div>

        <form className="loginForm" onSubmit={handleSubmit}>
          <input
            placeholder="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            placeholder="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button>Ingresar</button>
        </form>
        
      </div>
    </div>
  );
};

export default Login;