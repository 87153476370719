import React, { useState } from "react";
import { auth, db } from "../base";
import "./Signup.css";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ref, set } from "firebase/database";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [src, setSrc] = useState("");
  const [preKey, setPreKey] = useState("");
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();

  const key = "5tBdjRC~L!aR[y:''^#+";

  const handleSubmit = (e) => {
    e.preventDefault();
    function onRegister() {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          set(ref(db, "users/" + userCredential.user.uid), {
            firstName: firstName,
            lastName: lastName,
            email: email,
            src: src,
          });
        })
        .catch((error) => console.log(error));
      navigate("/");
    }
    onRegister();
  };
  const handleSubmitKey = (e) => {
    e.preventDefault();
    function onRegister() {
      if(preKey === key) {
        setFlag(true)
      }
    }
    onRegister();
  };

  return (
    <div className="signup">
      <div className="container">
      { !flag ? 
        <form className="signupForm" onSubmit={handleSubmitKey}>
        <input
            placeholder="Clave de Acceso"
            onChange={(e) => setPreKey(e.target.value)}
            required
            type="password"
          ></input>
          <button>Ingresar</button>
        </form>
        :
        <div>
          <div className="title">
            <h2>Estudio <strong>LMG</strong></h2>
            <p className="lead">Creación de usuarios</p>
          </div>
          <form className="signupForm" onSubmit={handleSubmit}>
            <input
              placeholder="Nombre"
              onChange={(e) => setFirstName(e.target.value)}
              required
            ></input>
            <input
              placeholder="Apellido"
              onChange={(e) => setLastName(e.target.value)}
              required
            ></input>
            <input
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
            ></input>
            <input
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              required
              type="password"
            ></input>
            <input
              placeholder="Link PowerBI"
              onChange={(e) => setSrc(e.target.value)}
              required
            ></input>
            <button>Registrar Usuario</button>
          </form>
        </div>
      }
      </div>
    </div>
  );
};

export default SignUp;