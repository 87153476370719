import React, { useState, useContext, useEffect } from "react";
import { auth, db } from "../base";
import { signOut } from "firebase/auth";
import { AuthContext } from "../services/AuthProvider";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import Welcome from "../components/Welcome";
import Dashboard from "../components/Dashboard";
import NavBar from "../components/NavBar";

function Home() {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser) {
      const starCountRef = ref(db, "users/" + currentUser.uid);
      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          var data = snapshot.val();
          setUserData(data);
        }
      });
    }
  }, [currentUser]);

  const clickLogin = () => {
    if (currentUser) {
      signOut(auth);
    } else {
      navigate("/login");
    }
  };

  // const clickSignup = () => {
  //   navigate("/signup");
  // };

  return (
    <div className="mainContainer home">
      <div className="container">
      {
        !currentUser ? 
        <Welcome clickLogin={ clickLogin } /> :
        <div>
          <NavBar user={userData} signOut={clickLogin}/>
          <Dashboard user={userData} />
        </div>
      }
        
      </div>
    </div>
  );
}

export default Home;