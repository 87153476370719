import React from 'react'
import "./NavBar.css"

export default function NavBar({user, signOut}) {
  return (
    <div className='navbar'>
      <img src='https://www.divisionclientes.com.ar/assets/img/logo_color.svg' alt='' />
      <div>{user.firstName + " " + user.lastName}</div>
      <button className='button-signout' onClick={signOut}>
          Cerrar Sesión
        </button>
    </div>
  )
}
