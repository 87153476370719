import React from 'react'
import './Welcome.css'
import logo from '../assets/logos/Logo_LMG_Clientes.png'

export default function Welcome({clickLogin}) {
  return (
    <div>
      <div className='logo-home'>
        <img src={logo} alt='Estudio LMG, División Clientes' />
      </div>
      <div className="title">
        <h3>Bienvenido a</h3>
        <h2>División <strong>Clientes</strong></h2>
        <h2 className='sub-title'>ESTUDIO LMG</h2>
        <p className="lead">Creando soluciones para nuestros clientes</p>
      </div>
      
      <div className="buttons">
        <button onClick={clickLogin}>
          Iniciar Sesión
        </button>
        {/* {!currentUser && <button onClick={clickSignup}>Sign Up</button>} */}
      </div>
    </div>
  )
}
