import React from 'react'
import './Dashboard.css'

export default function Dashboard({user}) {
  return (
    <div className='dashboard'>
      <div>
          <h3>Reporte:</h3>
          <iframe title="Report Section" width="100%" src={ user.src } frameborder="0" allowFullScreen="true"></iframe>
        </div>
    </div>
  )
}
